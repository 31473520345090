<template>
  <div>
    <!-- Create -->
    <div class="flex items-center ml-2">
      <div>
        <v-popover
          class="w-full new-button-container"
          :popover-base-class="'tooltip popover tooltip-white p-0'"
        >
          <template v-if="isInMiniMode">
            <a
              href="#"
              class="inline-flex items-center px-3 py-2 mr-2 text-xs font-medium leading-4 text-gray-100 bg-transparent border border-transparent rounded cursor-pointer focus:outline-none"
            >
              <fa-icon
                icon="plus"
                class=""
              />
            </a>
          </template>
          <template v-else>
            <a
              href="#"
              class="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out bg-gray-300 border border-transparent rounded cursor-pointer dark:text-gray-300 dark:bg-transparent dark:border-gray-600 hover:bg-gray-400 focus:outline-none focus:border-gray-700 focus:ring-gray active:bg-gray-500"
              :class="{'btn-no-style btn-xs text-white': isInMiniMode }"
            >
              New
              <fa-icon
                icon="caret-down"
                class="ml-1"
              />
            </a>
          </template>

          <template slot="popover">
            <ul
              class="w-48 leading-loose text-gray-900 border-b sm:border-none "
              :class="[ isInMiniMode ? 'text-xs' : 'text-sm' ]"
            >
              <li v-if="isInMiniMode">
                <a
                  v-close-popover
                  href="#"
                  class="flex max-w-sm p-4 mx-auto bg-white border-b border-gray-200 hover:bg-gray-200"
                  @click.prevent="showModalContentUpload"
                >
                  <div class="shrink-0">
                    <fa-icon
                      icon="upload"
                      size="lg"
                      class="mx-2 text-gray-700"
                    />
                  </div>

                  <div class="pt-1 ml-2">
                    <h4 class="mb-1 leading-tight">Upload Content</h4>
                  </div>
                </a>
              </li>

              <li>
                <a
                  v-close-popover
                  href="#"
                  class="flex max-w-sm p-4 mx-auto bg-white border-b border-gray-200 hover:bg-gray-200"
                  @click.prevent="showModalFolderCreate"
                >
                  <div class="shrink-0">
                    <fa-icon
                      icon="folder"
                      size="lg"
                      class="mx-2 text-blue-500"
                    />
                  </div>
                  <div class="pt-1 ml-2">
                    <h4 class="mb-1 leading-tight">New Folder</h4>
                  </div>
                </a>
              </li>
              <li>
                <a
                  v-close-popover
                  href="#"
                  class="flex max-w-sm p-4 mx-auto bg-white border-b border-gray-200 hover:bg-gray-200"
                  @click.prevent="showModalSceneCreate"
                >
                  <div class="shrink-0">
                    <fa-icon
                      icon="cube"
                      size="lg"
                      class="mx-2 text-orange-500"
                    />
                  </div>
                  <div class="pt-1 ml-2">
                    <h4 class="mb-1 leading-tight">Scene</h4>
                  </div>
                </a>
              </li>

              <li>
                <a
                  v-close-popover
                  href="#"
                  class="flex max-w-sm p-4 mx-auto bg-white hover:bg-gray-200"
                  :class="{'border-b border-gray-200' : showCompositionCreate, 'disabled opacity-50 cursor-not-allowed' : !hasScenesForSequenceCreate }"
                  @click.prevent="showModalSequenceFullCreate"
                >
                  <div class="shrink-0">
                    <fa-icon
                      icon="film"
                      size="lg"
                      class="mx-2 text-purple-500"
                    />
                  </div>
                  <div class="pt-1 ml-2">
                    <h4 class="mb-1 leading-tight">Sequence</h4>
                  </div>
                </a>
              </li>

              <li v-if="showCompositionCreate">
                <a
                  v-close-popover
                  href="#"
                  class="flex max-w-sm p-4 mx-auto bg-white hover:bg-gray-200"
                  @click.prevent="showModalCompositionCreate"
                >
                  <div class="shrink-0">
                    <fa-icon
                      icon="layer-group"
                      size="lg"
                      class="mx-2 text-green-500"
                    />
                  </div>
                  <div class="pt-1 ml-2">
                    <h4 class="mb-1 leading-tight">Composition</h4>
                  </div>
                </a>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>

      <input
        id="upload-content-file-input"
        ref="uploadContentFileInput"
        class="hidden"
        name="asset"
        type="file"
        value="Upload Asset"
        multiple
        accept="image/png,image/jpeg"
        @change="handleContentInput"
      >

      <div
        v-if="!isInMiniMode"
        class="inline-flex items-center px-3 py-2 ml-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out bg-blue-600 border border-transparent rounded cursor-pointer hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700"
        @click.prevent="showModalContentUpload"
      >
        <fa-icon
          icon="upload"
          class="mr-2"
        /> Upload
      </div>
    </div>
  </div>
</template>
<script>
// const ContentUpload = () => import('@modals/ContentUpload')
const SceneCreate = () => import('@modals/SceneCreate')
const SequenceFullCreate = () => import('@modals/SequenceFullCreate')
const FolderCreate = () => import('@modals/FolderCreate')
const CompositionCreate = () => import('@modals/CompositionCreate')

export default {
  name: 'ExplorerToolbarCreate',

  props: {
    project: {
      required: true,
      type: Object
    },

    activeFolder: {
      required: true,
      type: Object
    },

    navigate: {
      type: Boolean,
      default: false
    },

    displayMode: {
      type: String,
      default: 'full'
    }
  },

  computed: {
    isInMiniMode () {
      return this.displayMode === 'mini'
    },

    showCompositionCreate () {
      return this.$store.getters['app/getFlag']('contentCompositionsEnabled')
    },

    hasScenesForSequenceCreate () {
      return this.scenesForSequenceCreate.length > 0
    },

    scenesForSequenceCreate () {
      return this.$store.getters['assets/getRecentAssetsByType'](this.project.id, 'scene')
    }
  },

  methods: {

    showModalContentUpload () {
      let el = this.$refs.uploadContentFileInput
      el.click()
    },

    showModalSequenceFullCreate () {
      if (!this.hasScenesForSequenceCreate) return

      this.$modal.show(
        SequenceFullCreate, {
          parent: this.activeFolder.id,
          project: this.project,
          scenes: this.scenesForSequenceCreate
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    showModalFolderCreate () {
      this.$modal.show(
        FolderCreate, {
          parent: this.activeFolder.id,
          project: this.project,
          navigate: this.navigate
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    showModalCompositionCreate () {
      this.$modal.show(
        CompositionCreate, {
          parent: this.activeFolder.id,
          project: this.project,
          navigate: this.navigate
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    showModalSceneCreate () {
      this.$modal.show(
        SceneCreate, {
          parent: this.activeFolder.id,
          project: this.project
        }, {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    handleContentInput (event) {
      const input = this.$refs.uploadContentFileInput
      this.onDirectUpload(input)
    },

    onDirectUpload: function (input) {
      const project = this.project
      let res = []

      Array.from(input.files).forEach((file) => {
        res.push(this.$store.dispatch('assets/upload', { file: file, project: project, parentId: this.activeFolder.id }))
      })

      input.value = ''

      if (this.navigate === true) {
        // if(count > 1) {
        //   // More than one thing was uploaded, navigate to the parent folder
        //   this.$router.push({
        //     name: 'content_folder',
        //     params: {
        //       uuid: this.activeFolder.id,
        //       slug: this.project.slug
        //     }
        //   })
        // } else {
        //   // Just one was uploaded - navigate to it directly
        //   console.log('RES IS ', res)
        // }
      }
    }

  }
}
</script>
